// i18next-extract-mark-ns-start online-payments
import React from 'react';
import styled, {css} from 'styled-components';

import {PageProps, graphql, navigate} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

import payment_gateway from 'images/payment_gateway.svg';
import custom_checkout_page_en from 'images/custom_checkout_page_en.png';
import custom_checkout_page_es from 'images/custom_checkout_page_es.png';
import pay_by_link_EN from 'images/pay-by-link-feature.svg';
import pay_by_link_ES from 'images/pay-by-link-feature_es.svg';
import cards from 'images/cards.svg';
import cart from 'images/cart.svg';
import site_template from 'images/site_template.svg';
import shield from 'images/shield.svg';
import coinstack from 'images/coinstack.svg';
import trendgraph from 'images/trendgraph.svg';
import person from 'images/person.svg';
import payment_page_EN from 'images/payment-page-preview.png';
import payment_page_ES from 'images/payment-page-preview_es.png';

import {AnchorLink} from 'components/AnchorLink';
import {Button} from 'components/Button';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Clients, defaultClientList} from 'components/Clients';
import CardsContainer from 'components/CardsContainer';
import {Card} from 'components/Card';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {ScreenSizes, Sx} from '../../types/responsive';
import {SignUpButton} from 'components/SignUpButton';
import {InternalPageLink} from 'components/links/Pages';
import {SupportLink} from 'components/links/Support';
import {HomePaymentMethods} from 'components/HomePaymentMethods';
import {StyledDiv, StyledImg, StyledP, StyledSection} from 'components/StyledSx';
import {HomePartners} from 'components/HomePartners';
import {GoogleReviews, Place} from 'components/GoogleReviews';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import {FaqsSection} from 'components/FaqsSection';
import CodeSnippets from 'components/CodeSnippets';
import {BlogLink} from 'components/links/Blog';

type SectionProps = {
  textAlign?: 'center' | 'left' | 'right';
  centered?: boolean;
  reverseOnMobile?: boolean;
  column?: boolean;
  columnMd?: boolean;
  columnLg?: boolean;
  sx?: Sx;
};

export const Background = styled(StyledDiv)`
  position: relative;
  padding-top: 100px;
  ${Section} {
    color: #ffffff;
    a {
      color: #ffffff;
    }
    a:hover {
      color: #efefef;
    }
  }

  @media (max-width: 768px) {
    padding-top: 0;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    transform: skew(0, -15deg);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(40deg, #12e0c2 0%, #aab4e9 40%);
  }
  &:after {
    display: block;
    position: absolute;
    content: '';
    z-index: -1;
    right: 0;
    left: 0;
    bottom: 0;
    height: 80%;
    background-image: linear-gradient(30deg, #12e0c2 0%, #aab4e9 40%);
  }
`;

const IndexBackground = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -12%;
    height: 1256px;
    width: 1203px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1248, 4) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: 1024px) {
      top: -11.5%;
      transform: matrix(2, 0, 0, -1, 1185, 25) rotate(44.5deg) skew(7deg, 1.6deg);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(34deg) matrix(1, 0, 0, -1, 459, -315);
    left: 50%;
    top: -3%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: 1024px) {
      top: -13%;
      transform: rotate(34deg) matrix(1, 0, 0, -1, 470, -240);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }
`;

const IndexImage = styled.img`
  position: absolute;
  left: 56%;
  top: 20px;
  width: 540px;
  z-index: 9;

  @media (max-width: 1024px) {
    left: 60%;
    width: 45%;
    top: 1%;
  }
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 0;
  max-width: 550px;
  min-height: 600px;
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 500px;
    min-height: 510px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    max-width: 100%;
    min-height: 410px;
  }
`;

const FirstSection = styled(Section)`
  position: relative;
  padding-top: 0;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

const CardWrapper = styled.div`
  max-width: 230px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const FirstSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 24px;
  }
`;

const PaymentCard = styled(Card)`
  padding: 32px 62px;
  flex-basis: 100%;
  margin-top: -60px;

  @media (max-width: 768px) {
    margin-top: 0;
    padding: 40px 32px;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

export const CustomSection = styled(StyledSection)<SectionProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  padding-bottom: 100px;
  text-align: ${(props) => props.textAlign || 'left'};
  margin: 0 -30px;

  & > * {
    flex: 1;
    margin: 0 30px;

    @media (max-width: ${ScreenSizes.lg}) {
      flex: 3;
    }
    @media (max-width: ${ScreenSizes.md}) {
      margin: 0;
    }
  }

  a {
    text-decoration: underline;
  }

  ${(props) =>
    props.centered &&
    css`
      justify-content: center;
      align-items: center;
    `};
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `};

  @media (max-width: ${ScreenSizes.xl}) {
    padding-bottom: 15%;
  }
  @media (max-width: ${ScreenSizes.lg}) {
    ${(props) =>
      props.columnLg &&
      css`
        width: 100%;
        align-items: center;
        flex-direction: ${props.reverseOnMobile ? 'column-reverse' : 'column'};
      `};
  }
  @media (max-width: ${ScreenSizes.md}) {
    padding-bottom: 10%;
    ${(props) =>
      props.columnMd &&
      css`
        width: 100%;
        align-items: center;
        flex-direction: ${props.reverseOnMobile ? 'column-reverse' : 'column'};
      `};
  }
  @media (max-width: ${ScreenSizes.sm}) {
    padding-bottom: 80px;
    flex-direction: ${(props) => (props.reverseOnMobile ? 'column-reverse' : 'column')};
  }
  @media (max-width: ${ScreenSizes.md}) {
    margin: 0;
  }
`;

const OnlinePayments: React.FC<PageProps> = (props) => {
  const {t, language} = useI18next();
  const customCheckout = language === 'es' ? custom_checkout_page_es : custom_checkout_page_en;
  const paymentPagePreview = language === 'es' ? payment_page_ES : payment_page_EN;
  const payByLink = language === 'es' ? pay_by_link_ES : pay_by_link_EN;

  const {data}: any = props;

  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };

  const faqs = [
    {
      header: t('What types of payment methods should I offer?'),
      text: t(
        'The payment methods you offer should cater to your target audience. Common options include credit and debit cards, digital wallets like Apple Pay, Google Pay, and Click to Pay, bank transfers like SEPA Direct Debit, and local payment methods like Bizum (Spain), MB WAY (Portugal).'
      ),
      content: (
        <Trans>
          The <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> you offer
          should cater to your target audience. Common options include{' '}
          <InternalPageLink slug="payment-methods/credit-cards">
            credit and debit cards
          </InternalPageLink>
          , <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
          <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>, and{' '}
          <InternalPageLink slug="payment-methods/click-to-pay">Click to Pay</InternalPageLink>,
          bank transfers like{' '}
          <InternalPageLink slug="payment-methods/sepa-direct-debit">
            SEPA Direct Debit
          </InternalPageLink>
          , and <BlogLink slug="local-payment-methods">local payment methods</BlogLink> like{' '}
          <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> (Spain),{' '}
          <InternalPageLink slug="payment-methods/mb-way">MB WAY</InternalPageLink> (Portugal) to
          attract a wider range of customers.
        </Trans>
      )
    },
    {
      header: (
        <Trans parent="div">
          How do I ensure <BlogLink slug="secure-payments">secure online payments</BlogLink>?
        </Trans>
      ),
      text: t(
        'Use a payment gateway that’s PCI DSS compliant, supports 3D Secure checkout and strong customer authentication (SCA), and uses tokenization to store sensitive payment data and protect transactions.'
      ),
      content: (
        <Trans>
          Use a <InternalPageLink slug="payment-gateway">payment gateway</InternalPageLink> that’s{' '}
          <InternalPageLink slug="pci-dss">PCI DSS compliant</InternalPageLink>, supports{' '}
          <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">3D Secure</BlogLink>{' '}
          checkout and{' '}
          <BlogLink slug="strong-customer-authentication">
            strong customer authentication (SCA)
          </BlogLink>
          , and uses{' '}
          <BlogLink slug="what-is-tokenization-and-its-benefits-for-e-commerce">
            tokenization
          </BlogLink>{' '}
          to{' '}
          <AnchorLink href="https://docs.monei.com/docs/guides/save-payment-method/#2-obtain-and-store-payment-token">
            store sensitive payment data
          </AnchorLink>{' '}
          and protect transactions.
        </Trans>
      )
    },
    {
      header: t('What are chargebacks and how can I reduce them?'),
      text: t(
        'A chargeback occurs when a customer disputes a charge, and the funds are returned to them. This can be costly and damaging to your reputation. To minimize chargebacks, clearly describe products/services, offer excellent customer service, and use fraud prevention tools. Always provide a clear and easy-to-find refund policy, and communicate with dissatisfied customers proactively.'
      ),
      content: (
        <Trans>
          A chargeback occurs when a customer disputes a charge, and the funds are returned to them.
          This can be costly and damaging to your reputation. To{' '}
          <BlogLink slug="chargebacks-prevention">minimize chargebacks</BlogLink>, clearly describe
          products/services, offer excellent customer service, and use fraud prevention tools.
          Always provide a clear and easy-to-find refund policy, and communicate with dissatisfied
          customers proactively.
        </Trans>
      )
    },
    {
      header: t('What are the fees for online payments?'),
      text: t(
        'Fees can vary depending on your payment service provider (PSP). Typically, there are transaction fees, which are a percentage of each sale plus a fixed amount. There may also be setup fees, monthly fees, and additional costs for services like chargeback disputes or international transactions. Explore MONEI’s pricing.'
      ),
      content: (
        <Trans>
          Fees can vary depending on your{' '}
          <BlogLink slug="what-is-a-psp">payment service provider (PSP)</BlogLink>. Typically, there
          are transaction fees, which are a percentage of each sale plus a fixed amount. There may
          also be setup fees, monthly fees, and additional costs for services like chargeback
          disputes or international transactions.{' '}
          <InternalPageLink slug="pricing">Explore MONEI’s pricing</InternalPageLink>.{' '}
        </Trans>
      )
    },
    {
      header: t('How do I handle international payments?'),
      text: t(
        'If you’re catering to an international audience, you should offer local payment methods. And with a payment gateway like MONEI, you don’t need a different integration for each country you want to sell to. Configure a range of popular payment methods throughout Europe in your account.'
      ),
      content: (
        <Trans>
          If you're catering to an international audience, you should offer local payment methods.
          And with a payment gateway like MONEI, you don’t need a different integration for each
          country you want to sell to. Configure a range of popular payment methods throughout
          Europe{' '}
          <AnchorLink href="https://dashboard.monei.com/settings/payment-methods">
            in your account
          </AnchorLink>
          .
        </Trans>
      )
    }
  ];

  const paymentCurl = `
  curl --request POST 'https://api.monei.com/v1/payments'
  --header 'Authorization: <API_KEY>'
  --header 'Content-Type: application/json'
  --data-raw '{
    "amount": 110,
    "currency": "EUR",
    "orderId": "14379133960355",
    "description": "Test Shop - #14379133960355",
    "customer": {
      "email": "john.doe@microapps.com"
    },
    "callbackUrl": "https://example.com/checkout/callback",
    "completeUrl": "https://example.com/checkout/complete",
    "cancelUrl": "https://example.com/checkout/cancel"
}'
`;

  const snippets = [{code: paymentCurl, language: 'javascript'}];

  const clientsList = defaultClientList.slice(0, 5).map((client) => {
    return {
      ...client,
      width: 180 * client.scale
    };
  });

  return (
    <>
      <SEO
        path="online-payments"
        title={t('Online Payments')}
        description={t(
          'Use the all-in-one online payments platform to accept more digital payment methods with a single integration. Grow your e-commerce business faster with MONEI.'
        )}
      />
      <IndexBackground>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Online payments infrastructure built for growth</Trans>
            </SectionHeader>
            <Trans parent="p">
              Join thousands of businesses in Southern Europe ranging in size from startups to SMEs
              to large enterprises — offer online payments to customers around the world with
              MONEI’s all-in-one payments platform.
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
            <StyledP
              sx={{color: '#74858E', marginTop: '16px', fontSize: '14px', fontStyle: 'italic'}}>
              <Trans>
                *Currently supporting merchants in Spain and{' '}
                <InternalPageLink slug="andorra-payment-gateway">Andorra</InternalPageLink>
              </Trans>
            </StyledP>
          </IndexContent>
        </Content>
        <IndexImage
          src={payment_gateway}
          alt="MONEI payment gateway"
          title="MONEI payment gateway"
          className="hide-on-mobile"
        />
        <Content>
          <FirstSection>
            <Clients clientList={clientsList} />
          </FirstSection>
        </Content>

        <Content>
          <CardsContainer>
            <Card>
              <CardImage src={cart} alt="" style={{top: -25}} />
              <Trans parent="h4">Increase conversions</Trans>
              <CardWrapper>
                <Trans parent="p">
                  Create a frictionless payment experience and offer customers their preferred{' '}
                  <InternalPageLink slug="payment-methods">payment methods</InternalPageLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={site_template} alt="" style={{top: -45, width: 120}} />
              <Trans parent="h4">Reduce engineering costs</Trans>
              <CardWrapper>
                <Trans parent="p">
                  Use a payments solution that’s built to scale — plug in with popular{' '}
                  <InternalPageLink slug="payment-method-integrations-ecommerce-platforms">
                    e-commerce platforms
                  </InternalPageLink>{' '}
                  or use our{' '}
                  <AnchorLink href="https://docs.monei.com/api/#tag/Payments">
                    Payments API
                  </AnchorLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={shield} alt="" style={{top: -15}} />
              <Trans parent="h4">Process secure digital payments</Trans>
              <CardWrapper>
                <Trans parent="p">
                  MONEI is <InternalPageLink slug="pci-dss">PCI compliant</InternalPageLink>,
                  supports 3D Secure checkouts, and uses tokenization to encrypt sensitive{' '}
                  <AnchorLink href="https://docs.monei.com/docs/guides/save-payment-method/">
                    payment information
                  </AnchorLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={coinstack} alt="" style={{top: -14, width: 100}} />
              <Trans parent="h4">Pay less on transaction fees</Trans>
              <CardWrapper>
                <Trans parent="p">
                  Get online payments that scale with your business — the more you sell, the less
                  you’ll spend on transaction{' '}
                  <SupportLink slug="/articles/360017954318">fees</SupportLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={trendgraph} alt="" style={{top: -20, width: 100}} />
              <Trans parent="h4">Leverage real-time payment analytics</Trans>
              <CardWrapper>
                <Trans parent="p">
                  Take control of{' '}
                  <AnchorLink href="https://dashboard.monei.com/">your business</AnchorLink> with
                  live, detailed online payment data at your fingertips
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={person} alt="" style={{top: -50, width: 100}} />
              <Trans parent="h4">Get expert support</Trans>
              <CardWrapper>
                <Trans parent="p">
                  From account creation to integration troubleshooting to configuring the right
                  payment methods, our payment technology experts are{' '}
                  <SupportLink slug="hc">here to help</SupportLink>
                </Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
        </Content>
      </IndexBackground>

      <Content>
        <Section>
          <FirstSectionHeader>
            <Trans parent="h2">Accept more online payment methods from one platform</Trans>
            <Trans parent="p">
              Grow your business internationally by accepting all the most popular{' '}
              <BlogLink slug="local-payment-methods">local payment methods</BlogLink>.
            </Trans>
          </FirstSectionHeader>
        </Section>
        <PaymentCard>
          <HomePaymentMethods />
          <Button variant="dark" onClick={() => navigate('/payment-methods/')}>
            <Trans>View All Payment Methods</Trans>
          </Button>
        </PaymentCard>
      </Content>

      <Content sx={{paddingTop: '100px'}}>
        <FirstSectionHeader>
          <Trans parent="h2">MONEI’s online payment platform at work </Trans>
          <Trans parent="p">
            Choose an online payments integration that suits your business needs.
          </Trans>
        </FirstSectionHeader>
        <Section sx={{alignItems: 'center', gap: '30px', paddingTop: '50px'}}>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg src={paymentPagePreview} alt="Monei pay" title="Monei pay" />
          </StyledDiv>
          <div>
            <SectionHeader>
              <Trans>Hosted Payment Page</Trans>
            </SectionHeader>
            <Trans parent="p">
              Use MONEI’s{' '}
              <AnchorLink href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/">
                prebuilt payment page
              </AnchorLink>{' '}
              to securely collect a wide range of payment methods.{' '}
              <SupportLink slug="/articles/360017814717">Customize your checkout</SupportLink> with
              your branding, logo, and company URL.
            </Trans>
          </div>
        </Section>
      </Content>

      <Background sx={{paddingBottom: {sm: '50px'}}}>
        <Content>
          <Section sx={{alignItems: 'center', paddingTop: '0px', paddingBottom: '0px'}}>
            <StyledDiv>
              <SectionHeader underline sx={{paddingTop: {sm: '50px'}}}>
                <Trans>Pay By Link</Trans>
              </SectionHeader>
              <Trans parent="p">
                Send{' '}
                <InternalPageLink slug="features/pay-by-link">
                  online payment requests
                </InternalPageLink>{' '}
                via any channel with or without a website using{' '}
                <AnchorLink href="https://docs.monei.com/docs/integrations/pay-by-link/">
                  Pay By Link
                </AnchorLink>
                .
              </Trans>
            </StyledDiv>
            <StyledDiv
              sx={{
                marginBottom: '0',
                display: {all: 'flex', sm: 'none'},
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <StyledImg src={payByLink} alt="Pay less" title="Pay less" sx={{width: '100%'}} />
            </StyledDiv>
          </Section>
        </Content>
      </Background>

      <Content>
        <Section sx={{alignItems: 'center', gap: '30px', paddingTop: {lg: '8%', sm: '20%'}}}>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg src={customCheckout} alt="Monei pay" title="Monei pay" />
          </StyledDiv>
          <div>
            <SectionHeader>
              <Trans>Custom Checkout</Trans>
            </SectionHeader>
            <Trans parent="p">
              Build a{' '}
              <AnchorLink href="https://docs.monei.com/docs/integrations/build-custom-checkout/">
                custom checkout
              </AnchorLink>{' '}
              using{' '}
              <AnchorLink href="https://docs.monei.com/docs/monei-js/overview/">
                MONEI Components
              </AnchorLink>{' '}
              to safely accept payments from your customers.
            </Trans>
          </div>
        </Section>
      </Content>

      <Content>
        <Section sx={{alignItems: 'center'}}>
          <StyledDiv>
            <SectionHeader underline>
              <Trans>Payments API</Trans>
            </SectionHeader>
            <Trans parent="p">
              Use MONEI’s{' '}
              <AnchorLink href="https://docs.monei.com/api/#tag/Payments">Payments API</AnchorLink>{' '}
              to securely perform a server to server payment.
            </Trans>
          </StyledDiv>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CodeSnippets snippets={snippets} />
          </StyledDiv>
        </Section>
      </Content>

      <Content>
        <Section textAlign="center">
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SectionHeader underline tagName="h2">
              <Trans>Easy online payments integration</Trans>
            </SectionHeader>
            <Trans parent="p" style={{maxWidth: 494, margin: 'auto'}}>
              Seamlessly connect with all{' '}
              <InternalPageLink slug="page/payment-method-integrations-ecommerce-platforms">
                major e-commerce platforms
              </InternalPageLink>{' '}
              or integrate with your custom-built website using our Payments API.
            </Trans>
            <HomePartners />
            <SignUpButton variant="light">
              <Trans>Open an Acount</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>

      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>

      <Content>
        <GradientBox
          wrapperStyle={{overflow: 'visible', marginTop: '60px'}}
          contentStyle={{maxWidth: {all: '650px', md: '100%'}}}>
          <SectionHeader tagName="h2" underline style={{color: 'white'}}>
            <Trans>Set up online payments today</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Accept more online payment methods, increase sales, and grow your online business with
              the best platform for e-commerce payments.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Join MONEI Now</Trans>
            </SignUpButton>
          </SectionActions>
          <GradientCtaImage
            sx={{
              bottom: '00',
              right: '70px',
              width: '22%'
            }}
            src={cards}
          />
        </GradientBox>
      </Content>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Online payments FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default OnlinePayments;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "online-payments"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
